import LightningTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-pigmentation/lightning/LightningTemplate';

export default {
  title: 'Steps/SkinPigmentation/Lightning/LightningTemplate',
  component: LightningTemplate
};

const createStory = props => () => ({
  components: { LightningTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><lightning-template v-bind="$options.storyProps" /></div>'
});

export const Base = createStory({
  wishesLightening: 'yes'
});
