<template>
  <div class="skin-lightening q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['wishesLightening'])">
      <single-answer-question
        title="label.wishesLightening"
        :value="wishesLightening"
        :options="$options.responseOptions"
        yes-no
        dysfunction="skin-pigmentation"
        @input="onFieldChange('wishesLightening', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { YES_NO_RESPONSE_OPTIONS } from '@/modules/questionnaire/constants/questions';

export default {
  name: 'LightningTemplate',
  components: { QuestionWithErrorWrap, SingleAnswerQuestion },
  mixins: [stepTemplateMixin],
  responseOptions: YES_NO_RESPONSE_OPTIONS,
  props: {
    wishesLightening: {
      type: String,
      default: ''
    }
  }
};
</script>
